import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { panelPageMargin } from "../../../utils/consts";
import Panel from "../../../components/v2/panel";
import BuddyGreeting from "../../../components/v2/buddyGreeting";
import ChatPrompts from "../../../components/v2/chatPrompts";
import { useState } from "react";
import axios from "axios";
import BottomChatBar from "../../../components/v2/bottomChatBar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";
const samplePrompts = [
  {
    text: "I'm concerned about the legal aspects of a situation that might involve the Protection of Children from Sexual Offences Act, 2012 (POCSO). Can you help me understand my rights and the next steps I should take?",
    emoji: "🔍",
  },
  {
    text: "I need guidance on handling a situation at work that may fall under the (Prevention, Prohibition and Redressal) Act, 2013 (PoSH). How can I proceed while ensuring my safety and legal rights?",
    emoji: "💼",
  },
  {
    text: "I'm considering filing for divorce under the Hindu Marriage Act, 1955. Can you provide information on the legal process and what I should prepare for?",
    emoji: "💔",
  },
  {
    text: "I have questions about my marriage registration under the Muslim Marriages Act, 1939. What are the key legal considerations I should be aware of?",
    emoji: "💍",
  },
  {
    text: "I'm interested in understanding the legal procedures for a Parsi marriage and divorce under the Parsi Marriage and Divorce Act, 1936. Can you guide me through the process?",
    emoji: "📜",
  },
  {
    text: "I want to know more about the Special Marriage Act, 1956, especially regarding interfaith marriages. What legal advice can you offer?",
    emoji: "🌈",
  },
  {
    text: "I'm looking for advice on protecting my child from online predators. Does this fall under the POCSO Act, and what steps can I take?",
    emoji: "👨‍👩‍👧‍👦",
  },
  {
    text: "I'm facing harassment at work and need to know how the PoSH Act can protect me. What are my legal options?",
    emoji: "🚫",
  },
  {
    text: "I'm in a difficult situation with my spouse and considering my options under the Hindu Marriage Act. Can you provide legal counseling?",
    emoji: "🤔",
  },
  {
    text: "I need assistance with the legal documentation required for a marriage under the Special Marriage Act, 1956. Can you help?",
    emoji: "📄",
  },
  {
    text: "I'm curious about the legal distinctions between the various marriage laws in India. Can you explain the differences and how they might affect me?",
    emoji: "🇮🇳",
  },
  {
    text: "I'm seeking guidance on resolving a workplace harassment issue sensitively and effectively under the PoSH Act. What are the best practices?",
    emoji: "🛡️",
  },
  {
    text: "I'm experiencing difficulties in my marriage and need advice on legal separation under the Muslim Marriages Act. Where do I start?",
    emoji: "🚶‍♂️",
  },
  {
    text: "I want to ensure my actions are compliant with the PoSH Act in a professional setting. Can you provide legal tips?",
    emoji: "💡",
  },
  {
    text: "I'm planning to get married under the Parsi Marriage and Divorce Act, 1936. What unique aspects should I consider?",
    emoji: "🕊️",
  },
  {
    text: "I'm feeling overwhelmed by the legal processes related to my marriage under the Special Marriage Act, 1956. Can you offer support and guidance?",
    emoji: "🙏",
  },
  {
    text: "I need to understand the implications of filing for divorce under the Hindu Marriage Act, 1955, especially regarding custody and alimony. Can you advise?",
    emoji: "👨‍⚖️",
  },
  {
    text: "I'm concerned about a friend who might be in a situation covered by the POCSO Act. How can I help them while ensuring legal protocols are followed?",
    emoji: "🤝",
  },
];
export default function LegalBuddy() {
  const [newQuestion, setNewQuestion] = useState("");
  const [loader, setLoader] = useState(false);
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: `Hi ${localStorage.getItem(
        "username"
      )}, Welcome to LegalBuddy, your AI legal assistant. I'm here to provide guidance on your legal questions and help with document review. Our conversation is confidential, so ask away with peace of mind. Let's address your legal needs together!`,
    },
  ]);
  const navigate = useNavigate();
  const handleSubmit = async (question) => {
    try {
      if (loader) return;
      if (!question && !newQuestion) return;
      let currentMessages = [...messages];
      setMessages([
        ...messages,
        { role: "user", content: question ? question : newQuestion },
      ]);
      currentMessages = currentMessages.concat({
        role: "user",
        content: question ? question : newQuestion,
      });
      setLoader(true);
      const response = await axios.post(
        "https://api.neuronxresearch.com/peacetwogether/chat-agent",
        {
          type: "legalbuddy",
          messages: currentMessages,
        }
      );
      currentMessages = currentMessages.concat({
        role: "assistant",
        content: response.data,
      });
      setMessages(currentMessages);
      setLoader(false);
      setNewQuestion("");
    } catch (e) {
      console.log(e.message);
      setLoader(false);
      alert(e.message);
    }
  };
  return (
    <Stack sx={{ ...panelPageMargin }}>
      <Panel />
      <Stack paddingX={{ md: 20 }} paddingY={5} spacing={2}>
        {messages.length === 1 && (
          <>
            <BuddyGreeting fullText="Welcome to LegalBuddy, your AI legal assistant. I'm here to provide guidance on your legal questions and help with document review. Our conversation is confidential, so ask away with peace of mind. Let's address your legal needs together!" />
            <Stack
              direction={{ xs: "row" }}
              spacing={2}
              gap={2}
              flexWrap={"wrap"}
              justifyContent={"center"}
              // paddingBottom={10}
              sx={{
                height: { xs: "200px", md: "240px" },
                overflow: "auto",
                scrollbarWidth: "none" /* Firefox */,
                msOverflowStyle: "none" /* Internet Explorer and Edge */,
                "&::-webkit-scrollbar": {
                  display: "none" /* Chrome, Safari, newer versions of Edge */,
                },
              }}
            >
              <ChatPrompts
                prompts={samplePrompts}
                setNewQuestion={setNewQuestion}
                handleSubmit={handleSubmit}
              />{" "}
            </Stack>
          </>
        )}
        {messages.length > 1 && (
          <>
            {" "}
            {messages.map((e, index) => (
              <Paper
                key={e.content + index}
                style={{
                  width: "auto",
                  alignSelf: e.role === "user" ? "flex-end" : "flex-start",
                  boxShadow: "none",
                  marginBottom:
                    index === messages.length - 1 && messages.length > 1
                      ? 300
                      : 5,
                }}
              >
                <Typography
                  variant="h6"
                  p={3}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    backgroundColor: e.role === "user" ? "#ffe3d0" : "#f0f4f9",
                    borderRadius: 3,
                  }}
                >
                  {e.content}
                </Typography>
                {e.role === "assistant" && index === messages.length - 1 && (
                  <Tooltip title="Consult with Legal Adviser">
                    <IconButton
                      onClick={() => navigate("/v2/client/consultants/true")}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Paper>
            ))}
          </>
        )}
        <BottomChatBar
          disclaimer="LegalBuddy is an AI powered legal assistant to guide you with general legal information but it does not create an attorney-client relationship. For decisions affecting your legal rights, please consult with a licensed lawyer."
          handleSubmit={handleSubmit}
          newQuestion={newQuestion}
          setNewQuestion={setNewQuestion}
          loader={loader}
        />
      </Stack>
    </Stack>
  );
}
